import { tv, VariantProps } from '@/utils/tv'

export const propertySearch = tv({
  slots: {
    base: [
      'w-full',
      'mx-auto',
      'rounded-lg',
      'bg-white shadow-md',
      'border border-gray-100',
      'overflow-hidden',
      'relative z-10'
    ],
    group: [
      'flex items-center',
      'w-full h-full',
      'flex-1',
      'p-3 lg:first:py-0',
      'lg:border-l border-gray-100 border-t lg:border-t-0',
      'lg:first:border-l-0 lg:last:border-l-0 first:border-t-0 last:border-t-0',
      'lg:last:max-w-44'
    ],
    select: ['p-0'],
    input: [],
    button: ['h-14'],
    tab: [
      'w-full lg:h-20',
      'flex flex-col lg:flex-row lg:items-center',
      'focus:outline-none',
      'data-[state=inactive]:hidden'
    ],
    buttonGroup: ['ml-6'],
    buttonTab: [
      'data-[state=inactive]:opacity-40',
      'data-[state=inactive]:translate-y-0.5',
      'rounded-b-none'
    ]
  }
})

export type PropertySearchVariantProps = VariantProps<typeof propertySearch>
