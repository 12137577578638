'use client'

import * as Tabs from '@radix-ui/react-tabs'
import { Select } from '@/components/data/select'
import { Input } from '@/components/data/input'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/data/switch'
import {
  type UsePropertySearchProps,
  usePropertySearch
} from './use-property-search'

export type PropertySearchProps = UsePropertySearchProps

const PropertySearch = (props: PropertySearchProps) => {
  const {
    styles,
    lang,
    filters,
    register,
    formState,
    onSubmit,
    currentTab,
    setCurrentTab
  } = usePropertySearch(props)

  const {
    locations,
    taxonomies: { building_finality, building_type }
  } = filters

  return (
    <Tabs.Root
      defaultValue="filter"
      onValueChange={(value) => setCurrentTab(value)}
      value={currentTab}
    >
      <Tabs.List className={styles.buttonGroup()} aria-label="Search">
        <Tabs.Trigger value="filter" asChild>
          <Button
            type="button"
            className={styles.buttonTab()}
            color="secondary"
            variant="solid"
            size="xs"
          >
            {lang.reference_common}
          </Button>
        </Tabs.Trigger>
        <Tabs.Trigger value="reference" asChild>
          <Button
            type="button"
            className={styles.buttonTab()}
            color="secondary"
            variant="solid"
            size="xs"
          >
            {lang.reference_search}
          </Button>
        </Tabs.Trigger>
      </Tabs.List>
      <div className={styles.base()}>
        <form noValidate onSubmit={onSubmit}>
          <Tabs.Content value="filter" className={styles.tab()} forceMount>
            <div className={styles.group()}>
              <Switch
                fullWidth
                items={building_finality.map(({ slug }) => ({
                  ...register('finalidade'),
                  label: lang[slug],
                  value: slug
                }))}
              />
            </div>
            <div className={styles.group()}>
              <Select
                {...register('tipo')}
                appearance="ghost"
                label={lang.type}
                placeholder={lang.all_properties}
                inputClassName={styles.select()}
                options={building_type.map(({ slug, title }) => ({
                  label: title,
                  value: slug
                }))}
              />
            </div>
            <div className={styles.group()}>
              <Select
                {...register('localizacao')}
                appearance="ghost"
                label={lang.location}
                placeholder={lang.all_locations}
                className={styles.select()}
                inputClassName={styles.select()}
                options={locations.map(({ slug, title }) => ({
                  label: title,
                  value: slug
                }))}
              />
            </div>
            <div className={styles.group()}>
              <Button
                type="submit"
                variant="solid"
                color="secondary"
                size="lg"
                fullWidth
                endIcon="search"
                loading={formState.isSubmitting}
                loadingLabel={lang.search}
                className={styles.button()}
              >
                {lang.search}
              </Button>
            </div>
          </Tabs.Content>
          <Tabs.Content value="reference" className={styles.tab()} forceMount>
            <div className={styles.group()}>
              <Input
                {...register('codigo')}
                appearance="ghost"
                label={lang.reference_search}
                placeholder={lang.reference_code}
                className={styles.input()}
                hideLabel
              />
            </div>
            <div className={styles.group()}>
              <Button
                type="submit"
                variant="solid"
                color="secondary"
                size="lg"
                fullWidth
                endIcon="search"
                loading={formState.isSubmitting}
                loadingLabel={lang.search}
                className={styles.button()}
              >
                {lang.search}
              </Button>
            </div>
          </Tabs.Content>
        </form>
      </div>
    </Tabs.Root>
  )
}

export default PropertySearch
